import SafeDiv from '../../../components/SafeDiv'
import './styles.css'

function TermsContent() {
    return (
        <SafeDiv>
            <div id='TermsDocx'>
                <p className="c12" id="h.gjdgxs"><span className="c29 c27 c39">S</span><span className="c37 c29 c27">ERVICESS</span><span
                    className="c29 c27 c37">&nbsp; &nbsp; &nbsp;</span></p>
                <p className="c12"><span className="c25 c27 c32">Termos e Condi&ccedil;&otilde;es de Uso</span></p>
                <p className="c12 c16"><span className="c25 c27 c32"></span></p>
                <p className="c12 c16"><span className="c25 c27 c32"></span></p>
                <p className="c12"><span className="c3 c22">1. Introdu&ccedil;&atilde;o &agrave; </span><span
                    className="c3 c22">SERVICESS</span></p>
                <p className="c12 c16"><span className="c15 c27 c32 c2"></span></p>
                <p className="c12"><span className="c24 c2">1.1 </span><span className="c6 c2">A </span><span
                    className="c6 c2">SERVICESS</span><span className="c0">&nbsp;&eacute; uma plataforma digital, com intuito de
                        fornecer os meios de contatos dos profissionais aos usu&aacute;rios, na inten&ccedil;&atilde;o de facilitar
                        as buscas entre os usu&aacute;rios, sendo uma comunidade, sem fins lucrativos.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">1.2 </span><span className="c6 c2">Nossa plataforma n&atilde;o fornece nenhum tipo
                    de an&uacute;ncio, e n&atilde;o possu&iacute;mos nenhum v&iacute;nculo com outras empresas, n&atilde;o
                    fornecemos seus dados pessoais, ou quaisquer dados mantidos pela </span><span
                        className="c6 c2">SERVICESS.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">1.3 </span><span className="c0">N&oacute;s fornecemos um espa&ccedil;o para troca de
                    mensagens entre os profissionais e os usu&aacute;rios, estas conversas s&atilde;o armazenadas e
                    criptografadas, podendo ser conTermsContentadas em casos de emerg&ecirc;ncias. O Usu&aacute;rio deve estar ciente
                    que toda negocia&ccedil;&atilde;o envolvida na contrata&ccedil;&atilde;o do servi&ccedil;o, feita fora da
                    plataforma, em contato direto com o profissional acontecer&aacute; fora do alcance da SERVICESS.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">1.4 </span><span className="c6 c2">Nossa plataforma &eacute; voltada para o
                    p&uacute;blico maior de 18 anos, n&atilde;o recomendamos o uso de adolescentes e crian&ccedil;as. Pessoas
                    com menos de 18 anos que omitir as informa&ccedil;&otilde;es, os respons&aacute;veis responder&atilde;o
                    pelos seus atos, conforme estabelecido neste Termo e Condi&ccedil;&otilde;es de Uso, resguardado pela
                </span><span className="c6 c2">SERVICESS.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">1.5 </span><span className="c0">&Eacute; proibido, e tomaremos as medidas
                    cab&iacute;veis aos usu&aacute;rios que fornecerem informa&ccedil;&otilde;es falsas dentro da nossa
                    plataforma.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c2 c24">1.6 </span><span className="c6 c2">N&oacute;s estamos em processo de
                    evolu&ccedil;&atilde;o, vamos aperfei&ccedil;oar e criar m&eacute;todos de seguran&ccedil;a em nossa
                    plataforma, como, o face ID, certifica&ccedil;&atilde;o de documentos, comprova&ccedil;&atilde;o de
                    atividade executada, entre outras. Com intuito de garantir a seguran&ccedil;a dos profissionais que
                    disponibilizamos aos nossos usu&aacute;rios, mas, apesar dos esfor&ccedil;os, existe possibilidade de
                    cria&ccedil;&atilde;o e falsifica&ccedil;&atilde;o de dados, a </span><span
                        className="c6 c2">SERVICESS,</span><span className="c0">&nbsp;ser&aacute; r&iacute;gida em combater perfis falsos e
                            mal-intencionados.</span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12"><span className="c24 c2">1.7 </span><span className="c0">Solicitamos aos usu&aacute;rios em nos alertar e
                    notificar acerca de erros, den&uacute;ncias, fraudes, perfis mal-intencionados, m&aacute; conduta de
                    profissionais, falta de respeito, ofensas, preconceitos, ou qualquer tipo de viol&ecirc;ncia moral,
                    intelectual ou digital, a fins de tratativa por parte da SERVICESS.</span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12"><span className="c15 c3 c22">2. Dos Direitos</span></p>
                <p className="c12 c16"><span className="c15 c27 c32 c2"></span></p>
                <p className="c12"><span className="c24 c2">2.1 </span><span className="c6 c2">Os usu&aacute;rios possuem direitos, todos,
                    prezados pela </span><span className="c6 c2">SERVICESS.</span></p>
                <p className="c12"><span className="c0">&nbsp;</span></p>
                <p className="c12 c16"><span className="c15 c3 c2"></span></p>
                <p className="c12"><span className="c24 c2">2.2 </span><span className="c6 c2">Os Usu&aacute;rios possuem alguns direitos no que
                    se refere aos seus dados pessoais e podem exerc&ecirc;-los entrando em contato conosco enviando um e-mail
                    para o&nbsp;</span><span className="c17 c2"><a className="c10"
                        href="https://www.google.com/url?q=https://ajuda.olx.com.br/fale-conosco&amp;sa=D&amp;source=editors&amp;ust=1664299915888722&amp;usg=AOvVaw2S4zJJdHhajHTvpHXQcW0L">canal
                        de atendimento</a></span><span className="c29 c2">.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <ul className="c28 lst-kix_list_4-0 start">
                    <li className="c12 c20 li-bullet-0"><span className="c0">Confirma&ccedil;&atilde;o da exist&ecirc;ncia de tratamento de
                        dados pessoais;</span></li>
                    <li className="c12 c20 li-bullet-0"><span className="c0">Corre&ccedil;&atilde;o de dados incompletos, inexatos ou
                        desatualizados;</span></li>
                    <li className="c12 c20 li-bullet-0"><span className="c0">Portabilidade dos dados;</span></li>
                    <li className="c12 c20 li-bullet-0"><span className="c0">Exclus&atilde;o de dados;</span></li>
                    <li className="c12 c20 li-bullet-0"><span className="c0">Informa&ccedil;&otilde;es sobre uso compartilhado de
                        dados.</span></li>
                </ul>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12"><span className="c15 c3 c22">3. Aos Prestadores de Servi&ccedil;os </span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12"><span className="c24 c2">3.1 </span><span className="c0">N&oacute;s coletamos seus dados, para garantir
                    seguran&ccedil;a e possibilitar a intermedia&ccedil;&atilde;o do servi&ccedil;o, ao criar um servi&ccedil;o,
                    o usu&aacute;rio assume a responsabilidade legal e moral do dom&iacute;nio do servi&ccedil;o criado, com
                    intuito de respeitar e atender a necessidade da pessoa que o procura.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">3.2 </span><span className="c6 c2">Ao aceitar esse termo, o usu&aacute;rio permite
                    que a </span><span className="c6 c2">SERVICESS,</span><span className="c0">&nbsp;disponibilize seus meios de
                        contatos pessoais a terceiros (Nome, Telefone, E-mail, Endere&ccedil;o, Redes Sociais), e que, estes
                        est&atilde;o devidamente autorizados em contact&aacute;-los pelos dados fornecidos ao criar o
                        servi&ccedil;o.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">3.3 </span><span className="c6 c2">Ao criar um perfil de servi&ccedil;o, o
                    usu&aacute;rio assume o compromisso de executar devidamente com a atividade profissional mencionada, e que,
                    a falsifica&ccedil;&atilde;o de identidade profissional &eacute; crime, podendo o usu&aacute;rio ser
                    condenado no</span><span className="c4">&nbsp;</span><span className="c3 c2 c9 c15">exerc&iacute;cio ilegal da
                        profiss&atilde;o (art. 47 da Lei de Contraven&ccedil;&otilde;es Penais)</span></p>
                <p className="c12 c16"><span className="c15 c3 c2 c9"></span></p>
                <p className="c12 c16"><span className="c15 c3 c2 c9"></span></p>
                <p className="c12"><span className="c24 c2 c9">3.4</span><span className="c27 c24 c2 c9">&nbsp;</span><span className="c0">Ao criar
                    uma conta no site, voc&ecirc; concorda que todas as informa&ccedil;&otilde;es fornecidas
                    (&ldquo;Informa&ccedil;&otilde;es da Conta&rdquo;) s&atilde;o verdadeiras, assumindo sempre a
                    responsabilidade de mant&ecirc;-las atualizadas.</span></p>
                <p className="c12 c16"><span className="c15 c3 c2 c9"></span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">3.5 </span><span className="c5 c2">O Prestador dever&aacute; ter em mente que, na
                    medida em que atue como um fornecedor de servi&ccedil;os, sua oferta o vincula, nos termos do artigo 30 do
                    C&oacute;digo de Defesa do Consumidor e do artigo 429 do C&oacute;digo Civil, cujo cumprimento poder&aacute;
                    ser exigido judicialmente pelo contratante.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c3 c22">4. Aos Usu&aacute;rios</span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12"><span className="c24 c2">4.1 </span><span className="c0">O usu&aacute;rio que n&atilde;o desejar prestar
                    nenhum servi&ccedil;o, tem o acesso livre a busca, utiliza&ccedil;&atilde;o e intera&ccedil;&atilde;o no
                    app, n&atilde;o sendo coletado nenhum dado.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">4.2 </span><span className="c0">O Usu&aacute;rio precisa respeitar as condutas de
                    bom uso do aplicativo e saber que, as a&ccedil;&otilde;es cometidas de forma a ferir princ&iacute;pios de
                    irregularidades, como fraudes, falsifica&ccedil;&atilde;o, poder&aacute; ser combatida, e &eacute; de teor
                    responsabilidade do usu&aacute;rio, as a&ccedil;&otilde;es dos seus atos.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">4.3 </span><span className="c6 c2">O Usu&aacute;rio deve estar ciente que ao aderir
                    a comunidade </span><span className="c6 c2">SERVICESS,</span><span className="c0">&nbsp;dever&aacute; cumprir com as
                        normas estabelecidas neste Termo e Condi&ccedil;&otilde;es de Uso, e que, responde interinamente pelos atos
                        cometidos dentro da plataforma. </span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12"><span className="c24 c2">4.4 </span><span className="c5 c2">Em virtude de a SERVICESS&nbsp;n&atilde;o figurar
                    como parte nas transa&ccedil;&otilde;es de contrata&ccedil;&atilde;o dos servi&ccedil;os que se realizam
                    entre os usu&aacute;rios, a responsabilidade por todas as obriga&ccedil;&otilde;es delas decorrentes, sejam
                    fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, ser&aacute; exclusivamente do
                    Contratante, do Prestador ou de ambos, conforme o caso.&nbsp;</span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12"><span className="c24 c2">4.5 </span><span className="c6 c2">O Usu&aacute;rio &eacute; respons&aacute;vel pela
                    repara&ccedil;&atilde;o de danos causados a terceiros, e a </span><span className="c6 c2">SERVICESS,</span><span
                        className="c0">&nbsp;oriundo de a&ccedil;&otilde;es executadas a partir do uso da nossa plataforma.</span></p>
                <p className="c12 c16"><span className="c0"></span></p>
                <p className="c12 c16"><span className="c2 c13"></span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12"><span className="c15 c3 c22">5. Das Condi&ccedil;&otilde;es de Uso</span></p>
                <p className="c12 c16"><span className="c15 c27 c32 c2"></span></p>
                <p className="c12"><span className="c24 c2">5.1 </span><span className="c6 c2">Para utilizar nosso app ou site, voc&ecirc;
                    precisa estar de acordo com a ideologia da comunidade </span><span className="c6 c2">SERVICESS.</span></p>
                <p className="c12 c16"><span className="c15 c26 c24 c2"></span></p>
                <p className="c12"><span className="c24 c2">5.2 </span><span className="c2 c6">A SERVICESS n&atilde;o se responsabiliza por
                    quaisquer condutas fraudulentas ocasionadas por usu&aacute;rios, podendo bloquear o cadastro de um
                    usu&aacute;rio na plataforma caso detecte ou suspeite de alguma conduta.</span></p>
                <p className="c21 c16"><span className="c25 c27 c32"></span></p>
                <p className="c21"><span className="c24 c2">5.3</span><span className="c27 c24 c30">&nbsp;</span><span className="c5 c2">Na
                    qualidade de classificado de Servi&ccedil;os, </span><span className="c5 c2">SERVICESS</span><span
                        className="c5 c2">&nbsp;n&atilde;o imp&otilde;e ou interfere em qualquer negocia&ccedil;&atilde;o sobre
                        condi&ccedil;&atilde;o, valor, qualidade, forma ou prazo na contrata&ccedil;&atilde;o entre os Contratantes
                        e Prestadores, tampouco garante a qualidade, ou entrega dos Servi&ccedil;os contratados entre os
                        Usu&aacute;rios.</span></p>
                <p className="c11"><span className="c3 c25"></span></p>
                <p className="c11"><span className="c25 c3"></span></p>
                <p className="c14"><span className="c15 c3 c22">6. Dos Dados</span></p>
                <p className="c14"><span className="c24 c2">6.1 </span><span className="c0">N&oacute;s coletamos alguns dados pessoais, para
                    poder disponibilizar a funcionalidade do aplicativo. S&atilde;o esses, os dados que coletamos de
                    voc&ecirc;:</span></p>
                <ul className="c28 lst-kix_list_2-0 start">
                    <li className="c21 c20 li-bullet-0"><span className="c0">Nome Completo;</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">E-mail;</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">N&uacute;mero de Telefone;</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Profiss&atilde;o;</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Fotos do dispositivo.</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Endere&ccedil;o</span></li>
                </ul>
                <p className="c21 c16"><span className="c0"></span></p>
                <p className="c21 c16"><span className="c15 c26 c24 c2"></span></p>
                <p className="c21"><span className="c24 c2">6.2 </span><span className="c6 c2">Entenda aqui, para quais motivos coletamos seus
                    dados</span><span className="c6">:</span></p>
                <p className="c21 c16"><span className="c0"></span></p>
                <ul className="c28 lst-kix_list_1-0 start">
                    <li className="c21 c20 li-bullet-0"><span className="c0">Permitir intera&ccedil;&atilde;o e compartilhamento de
                        informa&ccedil;&otilde;es com outros usu&aacute;rios;</span></li>
                    <li className="c20 c21 li-bullet-0"><span className="c0">Divulgar servi&ccedil;os;</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Fornecer os meios de contatos pessoais;</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Disponibilizar acesso &agrave; aplicativos de terceiros, como,
                        WhatsApp e E-mail, aos demais usu&aacute;rios da plataforma;</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Disponibilizar, de acordo com os dados fornecidos, sua
                        pr&aacute;tica profissional aos demais usu&aacute;rios;</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Possibilitar a SERVICESS, ser o instrumento intermediador e
                        divulgador dos servi&ccedil;os criados consensualmente por seus usu&aacute;rios.</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Utiliza&ccedil;&atilde;o da foto como
                        identifica&ccedil;&atilde;o do perfil.</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Cria&ccedil;&atilde;o e elabora&ccedil;&atilde;o de perfil
                        pessoal.</span></li>
                </ul>
                <p className="c11"><span className="c15 c26 c24 c34"></span></p>
                <p className="c14"><span className="c24 c2">6.3 </span><span className="c0">Entenda aqui, quais momentos coletamos seus
                    dados:</span></p>
                <ul className="c28 lst-kix_list_3-0 start">
                    <li className="c21 c20 li-bullet-0"><span className="c0">Quando o usu&aacute;rio utiliza o formul&aacute;rio de
                        contato;</span></li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Quando o usu&aacute;rio faz seu cadastro no site/app;</span>
                    </li>
                    <li className="c21 c20 li-bullet-0"><span className="c0">Quando o usu&aacute;rio edita seu perfil;</span></li>
                    <li className="c14 c20 li-bullet-0"><span className="c0">Quando o usu&aacute;rio cria seu servi&ccedil;o.</span></li>
                </ul>
                <p className="c11"><span className="c15 c26 c24 c2"></span></p>
                <p className="c14"><span className="c24 c2">6.4 </span><span className="c6 c2">Ao dar o aceite voc&ecirc; concorda com a coleta
                    e pol&iacute;tica de dados e os fornecimentos desses dados aqui exposto, fornecendo a SERVICESS, a liberdade
                    em utilizar estes dados para prestar seu servi&ccedil;o de intermedia&ccedil;&atilde;o, caso voc&ecirc;
                    queira saber mais sobre a </span><span className="c29 c27 c2">pol&iacute;tica de privacidade</span><span
                        className="c29 c2">&nbsp;</span><span className="c6 c2">e a respeito dos seus dados, consulte nosso documento
                            disponibilizado pelo app, ou por nosso site: </span><span
                                className="c29 c27 c2">www.servicess.com/politicadeprivacidade</span></p>
                <p className="c11"><span className="c15 c26 c24 c2"></span></p>
                <p className="c11"><span className="c15 c26 c24 c2"></span></p>
                <p className="c14"><span className="c15 c3 c22">7. Seguran&ccedil;a </span></p>
                <p className="c14"><span className="c24 c2">7.1 </span><span className="c0">Todos os dados s&atilde;o criptografados e
                    protegidos, o armazenamento deles s&atilde;o guardados em empresas terceirizadas que fornecem a
                    m&aacute;xima prote&ccedil;&atilde;o e preserva&ccedil;&atilde;o desses dados que coletamos de
                    voc&ecirc;.</span></p>
                <p className="c11"><span className="c0"></span></p>
                <p className="c12"><span className="c24 c2">7.2 </span><span className="c0">Os prestadores de servi&ccedil;os passam por
                    autentica&ccedil;&otilde;es dos seus meios de contato (Telefone, E-mail), com intuito de fornecer a
                    veracidade e titularidade da informa&ccedil;&atilde;o.</span></p>
                <p className="c11"><span className="c0"></span></p>
                <p className="c14"><span className="c2">7.3 </span><span className="c6 c2">A </span><span className="c6 c2">Servicess</span><span
                    className="c0">&nbsp;exige a utiliza&ccedil;&atilde;o de uma senha para criar uma conta. N&oacute;s nos
                    responsabilizamos pelo seu armazenamento, que s&atilde;o criptografados por nossos desenvolvedores. O acesso
                    a nossa plataforma se d&aacute; atrav&eacute;s de aplicativos de terceiros, como Google, e Facebook, que
                    disponibiliza um c&oacute;digo de acesso ou atrav&eacute;s da cria&ccedil;&atilde;o de conta direta pela
                    plataforma.</span></p>
                <p className="c11"><span className="c0"></span></p>
                <p className="c11"><span className="c0"></span></p>
                <p className="c40"><span className="c15 c3 c22">8. Dos Risco</span></p>
                <p className="c14"><span className="c24 c2">8.1</span><span className="c27 c24 c2">&nbsp;</span><span className="c3 c2">O
                    USU&Aacute;RIO AO DAR ACEITE NOS TERMOS DE USO EST&Aacute; DEVIDAMENTE CIENTE DOS RISCOS
                    POSSUINTES</span><span className="c6 c2">. A </span><span className="c6 c2">SERVICESS</span><span
                        className="c6 c2">&nbsp;COMBATE A DESINFORMA&Ccedil;&Atilde;O, FALSIDADE IDEOL&Oacute;GICA, FAKE NEWS, FRAUDE,
                        MAS, EXITEM RISCO, A SERVICESS N&Atilde;O RESPONSABILIZA PELOS ATOS DE TERCEIROS.</span><span
                            className="c2 c3">&nbsp;</span><span className="c0">Caso o prestador de servi&ccedil;o n&atilde;o seja o ideal ou a
                                altura do esperado, cabe ao usu&aacute;rio contratante tratar diretamente com o profissional, ou nos
                                contactar, nos casos de emerg&ecirc;ncias. </span></p>
                <p className="c11"><span className="c0"></span></p>
                <p className="c14"><span className="c2">8.2 </span><span className="c3 c2">A </span><span className="c3 c2">SERVICESS</span><span
                    className="c3 c2">&nbsp;POSSIBILITA OS MEIOS DE CONTATOS DOS PROFISSIONAIS, AOS USU&Aacute;RIOS. TODO O PROCESSO
                    DE NEGOCIA&Ccedil;&Atilde;O E GARANTIA DO SERVI&Ccedil;O OCORREM FORA DE ALCANCE E RESGUARDO DA
                    SERVICESS.</span></p>
                <p className="c11"><span className="c15 c27 c24 c2"></span></p>
                <p className="c14"><span className="c2">8.3</span><span className="c27 c2">&nbsp;</span><span className="c6 c2">N&Atilde;O
                    GARANTIMOS A </span><span className="c6 c2">VERACIDADE</span><span className="c6 c2">&nbsp;DOS SERVI&Ccedil;OS, como
                        comunidade, A </span><span className="c6 c2">SERVICESS</span><span className="c6 c2">&nbsp;FAZ A CONEX&Atilde;O
                            ENTRE PESSOAS. TODO PROCESSO DE CRIA&Ccedil;&Atilde;O NA PLATAFORMA &Eacute; LIVRE E </span><span
                                className="c6 c2">DESBUROCR&Aacute;TICO.</span></p>
                <p className="c11"><span className="c15 c27 c24 c2"></span></p>
                <p className="c11"><span className="c15 c27 c24 c2"></span></p>
                <p className="c33"><span className="c15 c3 c22">9. Indeniza&ccedil;&atilde;o</span></p>
                <p className="c12 c9"><span className="c24 c2">9.1 </span><span className="c0">O Usu&aacute;rio indenizar&aacute;&nbsp;a
                    SERVICESS, sua empresa controlada, controladores diretos ou indiretos, administradores, colaboradores,
                    representantes e empregados, inclusive quanto a honor&aacute;rios advocat&iacute;cios, por qualquer demanda
                    promovida por outros Usu&aacute;rios ou terceiros, decorrentes das atividades do primeiro na Plataforma, de
                    quaisquer descumprimentos, por aquele, dos&nbsp;Termos e das demais pol&iacute;ticas da SERVICESS ou, ainda,
                    de qualquer viola&ccedil;&atilde;o, pelo Usu&aacute;rio, de lei ou de direitos de terceiros.</span></p>
                <p className="c11"><span className="c15 c26 c24 c34"></span></p>
                <p className="c11"><span className="c15 c27 c24 c22"></span></p>
                <p className="c1"><span className="c15 c3 c22">10.</span><span className="c15 c26 c6 c22">&nbsp;</span><span
                    className="c15 c3 c22">Altera&ccedil;&atilde;o no Termos e Condi&ccedil;&otilde;es de Uso</span></p>
                <p className="c1"><span className="c15 c26 c24 c2">10.1</span><span className="c26 c24 c38">&nbsp;</span><span className="c0">Com a
                    constante evolu&ccedil;&atilde;o do modelo de neg&oacute;cio, a SERVICESS reserva-se o direito de alterar
                    este Termo e Condi&ccedil;&otilde;es de Uso a qualquer momento, mediante publica&ccedil;&atilde;o da
                    vers&atilde;o atualizada no Site e App. Caso exista uma altera&ccedil;&atilde;o material no tratamento dos
                    dados pessoais, voc&ecirc; ser&aacute; informado e ter&aacute; a oportunidade de analisar a pol&iacute;tica
                    revisada antes de decidir continuar usando nossos servi&ccedil;os.</span></p>
                <p className="c14"><span className="c0">&Uacute;ltima atualiza&ccedil;&atilde;o do TERMO: 25/09/2022 </span></p>
                <p className="c11"><span className="c15 c27 c24 c2"></span></p>
                <p className="c11"><span className="c15 c3 c22"></span></p>
                <p className="c33"><span className="c15 c3 c22">11. Das Responsabilidades</span></p>
                <p className="c14"><span className="c24 c2">11.1 </span><span className="c6 c2">A </span><span
                    className="c6 c2">SERVICESS,</span><span className="c6 c2">&nbsp;age como intermediador, disponibiliza esta
                        plataforma para conectar pessoas f&iacute;sicas ao encontro de profissionais. O USU&Aacute;RIO PRECISA
                        EST&Aacute; CIENTE QUE A SERVICESS N&Atilde;O TEM NENHUM V&Iacute;NCULO COM O PROFISSIONAL E QUE, O
                        PR&Oacute;PRIO, DEVER&Aacute; RESPONDER POR SUAS A&Ccedil;&Otilde;ES, A </span><span
                            className="c6 c2">SERVICESS</span><span className="c6 c2">&nbsp;PRESTA UM SERVI&Ccedil;O DE COMUNIDADE, NO INTUITO
                                DE AJUDAR.</span></p>
                <p className="c11"><span className="c15 c26 c2 c35"></span></p>
                <p className="c14"><span className="c24 c2">11.2 </span><span className="c6 c2">Nos d&aacute; </span><span
                    className="c6 c2">SERVICESS,</span><span className="c6 c2">&nbsp;seguiremos as condutas e normas jur&iacute;dicas
                        &nbsp; quando cab&iacute;veis, de acordo com a legisla&ccedil;&atilde;o brasileira. Ajudaremos a todo
                        momento nossos usu&aacute;rios, e as demandas necess&aacute;rias de cada um, estamos a dispor de qualquer
                        usu&aacute;rio que se sentir ferido, e daremos o apoio necess&aacute;rio a todos. Os usu&aacute;rios que
                        descumprirem normas ou agir fora dos princ&iacute;pios da lei, </span><span className="c6 c2">atribuiremos
                            as</span><span className="c6 c2">&nbsp;autoridades e &oacute;rg&atilde;os cab&iacute;veis a fim de punir aqueles
                                que cometerem algum tipo de irregularidade oriundas e geradas atrav&eacute;s da nossa plataforma. Tentamos
                                atrav&eacute;s deste Termo, limitar e atribuir responsabilidade a cada usu&aacute;rio, e que este necessita
                                pensar sobre suas a&ccedil;&otilde;es, ou m&aacute; conduta.</span></p>
                <p className="c11"><span className="c15 c27 c24 c36"></span></p>
                <p className="c14"><span className="c2 c9">11.3 </span><span className="c5 c2">A</span><span
                    className="c24 c2 c9">&nbsp;</span><span className="c2 c5">SERVICESS</span><span className="c5 c2">&nbsp;n&atilde;o
                        poder&aacute; ser responsabilizada pelo efetivo cumprimento das obriga&ccedil;&otilde;es assumidas pelos
                        usu&aacute;rios.&nbsp;Os usu&aacute;rios reconhecem e aceitam que, ao realizar negocia&ccedil;&otilde;es com
                        outros usu&aacute;rios, fazem-no por sua conta e risco, reconhecendo a SERVICESS como mero fornecedor de
                        servi&ccedil;os de disponibiliza&ccedil;&atilde;o de espa&ccedil;o virtual para an&uacute;ncio dos
                        servi&ccedil;os ofertados por terceiros.</span></p>
                <p className="c12 c16"><span className="c15 c27 c32 c2"></span></p>
                <p className="c12 c16"><span className="c15 c27 c32 c2"></span></p>
                <p className="c12 c16"><span className="c15 c27 c32 c2"></span></p>
                <p className="c12"><span className="c15 c3 c22">12. Contatos</span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c12"><span className="c24 c2">12.1 </span><span className="c6 c2">Voc&ecirc; pode entrar em contato com a gente a
                    qualquer momento, em nossos canais de atendimento ou diretamente em nosso site </span><span
                        className="c2 c17"><a className="c10"
                            href="https://www.google.com/url?q=http://www.servicess.com.br&amp;sa=D&amp;source=editors&amp;ust=1664299915903666&amp;usg=AOvVaw31pMMB3tJri9_RsevbIkli">www.servicess.com.br</a></span><span
                                className="c32 c2">&nbsp;</span><span className="c6 c2">ou</span><span className="c2 c32">&nbsp;</span><span
                                    className="c29 c27 c2">app.</span></p>
                <p className="c12 c16"><span className="c13 c2"></span></p>
                <p className="c1"><span className="c15 c26 c24 c2">12.2 </span><span className="c0">A </span><span
                    className="c0">SERVICESS</span><span className="c0">&nbsp;</span><span className="c0 c9">relembra que, este termo,
                        resguarda os </span><span className="c5 c2">direitos</span><span className="c0 c9">&nbsp;e deveres do usu&aacute;rio
                            com nossa plataforma. Este aplicativo &eacute; mantido e operado por uma futura empresa </span><span
                                className="c0 c9">Servicess</span><span className="c0 c9">&nbsp;Comunidade de Servi&ccedil;os LTDA.</span></p>
                <p className="c1 c16"><span className="c0 c9"></span></p>
                <p className="c12"><span className="c15 c26 c24 c2 c9">12.3 </span><span className="c0 c9">Nossa empresa tem o CNPJ: 48.200.886/0001-43
                    e est&aacute; localizada </span><span className="c5 c2">na Rua</span><span className="c0 c9">&nbsp;Padre Jo&atilde;o
                        Wislinski, 119, Santa C&acirc;ndida, Curitiba-PR. Contacto-nos no e-mail: </span><span
                            className="c17 c2 c9 c19"><a className="c10"
                                href="mailto:suporteservicess@gmail.com">suporte@servicess.com</a></span><span
                                    className="c17 c19 c2 c9">.br</span><span className="c15 c29 c27 c2 c9">&nbsp;</span><span className="c0 c9">Converse
                                        conosco: (41) 9 9134-6013</span></p>
                <p className="c12 c16"><span className="c15 c29 c27 c2 c9"></span></p>
                <p className="c12 c16"><span className="c15 c29 c27 c2 c9"></span></p>
                <p className="c1"><span className="c15 c3 c22">13.</span><span className="c3 c18">&nbsp;</span><span className="c15 c3 c22">Das
                    Dicas de Seguran&ccedil;a</span></p>
                <p className="c11"><span className="c15 c23"></span></p>
                <p className="c14"><span
                    style={{ overflow: "hidden", display: "inline-block", margin: "0.00px 0.00px", border: "0.00px solid #000000", transform: "rotate(0.00rad) translateZ(0px)", "-webkit-transform": "rotate(0.00rad) translateZ(0px)", width: "78.68px", height: "78.68px" }}><img
                        alt="Uma imagem contendo desenho

Descri&ccedil;&atilde;o gerada automaticamente" src={require("./terms_images/image1.png")}
                        style={{ width: "78.68px", height: "78.68px", marginLeft: "0.00px", marginTop: "0.00px", transform: "rotate(0.00rad) translateZ(0px)", "-webkit-transform": "rotate(0.00rad) translateZ(0px)" }}
                        title="" /></span></p>
                <p className="c11"><span className="c15 c26 c24 c2"></span></p>
                <p className="c14"><span className="c15 c3 c22">Aos Divulgadores de Servi&ccedil;os</span></p>
                <p className="c14"><span className="c0">&gt;Utilize um n&uacute;mero de contato profissional.</span></p>
                <p className="c14"><span className="c0">&gt;N&atilde;o forne&ccedil;a dados pessoais ou sigilosos.</span></p>
                <p className="c14"><span className="c0">&gt;Certifique de fornecer seu servi&ccedil;o em locais apropriados e em
                    &acirc;mbito profissional.</span></p>
                <p className="c14"><span className="c0">&gt;Certifique a veracidade da solicita&ccedil;&atilde;o do servi&ccedil;o.</span>
                </p>
                <p className="c14"><span className="c0">&gt;Mantenha uma conversa clara, e defina bem os acordos com a pessoa. </span></p>
                <p className="c14"><span className="c0">&gt;Somente preste o servi&ccedil;o quando se sentir seguro e acordado por ambas as
                    partes, caso a conversa viole algum princ&iacute;pio, notifique as autoridades cab&iacute;veis e comunique a
                    nossa equipe.</span></p>
                <p className="c14"><span className="c0">&gt;N&atilde;o preste servi&ccedil;os em casas domiciliares, n&oacute;s n&atilde;o
                    recomendamos prestadores de servi&ccedil;os que n&atilde;o possuem local pr&oacute;prio de
                    atendimento.</span></p>
                <p className="c11"><span className="c7"></span></p>
                <p className="c14"><span className="c15 c3 c22">Aos Usu&aacute;rios</span></p>
                <p className="c14"><span className="c0">&gt;Certifique de visitar o perfil do profissional;</span></p>
                <p className="c14"><span className="c0">&gt;Nunca forne&ccedil;a dados pessoais a terceiros;</span></p>
                <p className="c14"><span className="c0">&gt;Desconfie de perfil falsos;</span></p>
                <p className="c14"><span className="c0">&gt;Converse com o profissional, seja objetivo;</span></p>
                <p className="c14"><span className="c0">&gt;N&atilde;o aconselhamos o usu&aacute;rio a contratar servi&ccedil;os que demanda
                    ida a domic&iacute;lios;</span></p>
                <p className="c14"><span className="c0">&gt;Certifique que o local do servi&ccedil;o a ser prestado &eacute; um ambiente
                    profissional;</span></p>
                <p className="c14"><span className="c0">&gt;Nunca pague antes de receber o servi&ccedil;o.</span></p>
                <p className="c11"><span className="c15 c26 c24 c34"></span></p>
                <p className="c11"><span className="c15 c26 c24 c34"></span></p>
                <p className="c14"><span className="c3 c22">14.</span><span className="c6 c22">&nbsp;</span><span
                    className="c15 c3 c22">Considera&ccedil;&otilde;es Finais</span></p>
                <p className="c14"><span className="c24 c2">14.1 </span><span className="c6 c2">N&oacute;s prezamos o bom uso da comunidade, e
                    funcionalidade da plataforma. Contamos que o usu&aacute;rio fa&ccedil;a sua parte. </span><span
                        className="c27 c2 c29">Equipe </span><span className="c29 c27 c2">SERVICESS</span></p>
            </div>
        </SafeDiv>
    )
}

export default TermsContent