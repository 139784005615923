
import { IoLogoWhatsapp, IoMailOutline } from "react-icons/io5";
import ScAlertHome from "../../ScAlerts/ScAlertHome";
import './CMenuManageFraud.css';

function CMenuManageFraud({ setCentralMenu }: { setCentralMenu: any }) {

    return (
        <div
            style={{ backgroundColor: 'white', minHeight: '1000px', lineHeight: '1.6' }}

        >
            <div
                className="center column"
                id="CMenuManageFraud"
            >
                <strong className="CMenuManageFraud-a">🚨 Pediram para fazer pagamento?</strong>
                <strong className="CMenuManageFraud-b">NÃO faça, é golpe!</strong>
                <p className="CMenuManageFraud-c" >Nós <strong>NUNCA</strong> efetuamos cobranças por telefone, e-mail, WhatsApp ou outros.</p>
                <p className="CMenuManageFraud-d">Não passe seus dados, nós <strong>NUNCA</strong> pedimos seus dados.</p>
            </div>
            <div className="CMenuManageFraud-middle-div" style={{ padding: '40px 0' }}>
                <strong className="CMenuManageFraud-e"

                >
                    Canais oficiais:
                </strong>
                <ul style={{ marginTop: '30px' }}>
                    <li className="CMenuManageFraud-li">
                        <strong>
                            <strong style={{ marginRight: '6px' }}>
                                <IoMailOutline />
                            </strong>
                            E-mail: </strong>
                        suporte@servicess.com.br
                    </li>
                    <li className="CMenuManageFraud-li">
                        <strong>
                            <strong style={{ marginRight: '6px' }}>
                                <IoLogoWhatsapp />
                            </strong>
                            WhatsApp:
                        </strong>
                        <ol id="CMenuManageFraud-ol">                     
                            <li className="CMenuManageFraud-i">+55 41 9 9134-6013</li>
                        </ol>
                    </li>
                </ul>
            </div>
            <div className="center" style={{ marginTop: '-10px' }}>
                <button
                    onClick={() => setCentralMenu({ screen: 1, code: 'fraud' })}
                    className="CMenuManageFraud-btn"
                >
                    Atendimento
                </button>
            </div>
            <div className="CMenuManageFraud-middle-div" style={{ padding: '80px 0 40px 0' }}>
                <strong style={{ fontSize: '30px' }}>Informações complementares:</strong>
                <ol>
                    <li className="CMenuManageFraud-li">Gostariamos de reforçar que nós NÃO entramos em contato para efetuar vendas ou muito menos exigir qualquer tipo de pagamento.</li>
                    <li className="CMenuManageFraud-li">Atualmente, existe apenas a possibilidade de adquirir o plano "Premium", e a compra é feita exclusivamente dentro da plataforma, site ou aplicativo oficial.</li>
                    <li className="CMenuManageFraud-li">Os únicos canais de contato são os mencionados acima, usados exclusivamente para responder às suas solicitações. </li>
                    <li className="CMenuManageFraud-li">
                        <strong>
                            Conta bancaria Oficial:
                        </strong>
                        <ol style={{ listStyle: 'circle' }}>
                            <li className="CMenuManageFraud-li">
                                <p>Para: <strong>SERVICESS LTDA</strong></p>
                            </li>
                            <li className="CMenuManageFraud-li">
                                <p>Banco: <strong>Mercado Pago IP LTDA</strong></p>
                            </li>
                            <li className="CMenuManageFraud-li">
                                <p>{'Chave PIX (CNPJ): '} <strong>48200886000143</strong></p>
                            </li>
                            <li className="CMenuManageFraud-li">
                                <p>CNPJ: <strong>48.200.886/0001-43</strong></p>
                            </li>
                            <li className="CMenuManageFraud-li">
                                <p>Valor fixo: <strong>R$ 19,90</strong></p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>

            <B1 />

            <div className="CMenuManageFraud-middle-div" style={{ padding: '40px 0' }}>
                <strong style={{ fontSize: '30px' }}>
                    O que fazer agora?
                </strong>
                <p>
                    Em caso de contato não solicitado, ignore, bloqueie o número e informe-nos. Se possível, <strong>registre um boletim de ocorrência</strong> no Núcleo de Combate aos Cibercrimes da sua cidade.
                </p>
            </div>

            <div className="CMenuManageFraud-middle-div" style={{ padding: '40px 0' }}>
                <strong style={{ fontSize: '30px' }}>
                    Pediram seu cartão?
                </strong>
                <p>
                    <strong>Nunca</strong> compartilhe fotos dos seus cartões bancários. Se isso aconteceu, siga estes passos:
                </p>
                <ol>
                    <li className="CMenuManageFraud-li--2">
                        <strong>Bloqueie Imediatamente:</strong>
                        <ul style={{ listStyle: 'square', margin: '6px 0 0 20px' }}>
                            <li>Use o aplicativo do banco ou <strong>ligue para a central</strong> do seu banco.</li>
                        </ul>
                    </li>

                    <li className="CMenuManageFraud-li--2">
                        Monitore Transações:
                        <ul style={{ listStyle: 'square', margin: '6px 0 0 20px' }}>
                            <li>Fique de olho em atividades suspeitas.</li>
                        </ul>
                    </li>

                    <li className="CMenuManageFraud-li--2">
                        Atualize Senhas e PINs:
                        <ul style={{ listStyle: 'square', margin: '6px 0 0 20px' }}>
                            <li>Mantenha-os seguros e mude-os regularmente.</li>
                        </ul>
                    </li>

                    <li className="CMenuManageFraud-li--2">
                        Cuidado com Fraudes:
                        <ul style={{ listStyle: 'square', margin: '6px 0 0 20px' }}>
                            <li>Esteja atento a tentativas de <a href="https://support.microsoft.com/pt-br/windows/proteja-se-contra-phishing-0c7ea947-ba98-3bd9-7184-430e1f860a44" target="_blank" style={{ color: 'dodgerblue', textDecoration: 'underline' }}>phishing</a>.</li>
                        </ul>
                    </li>
                </ol>
            </div>

            <B1 />

            <div style={{ marginTop: '-70px', paddingBottom: '60px' }}>
                <ScAlertHome always />
            </div>

        </div>
    )
}

const B1 = () => (
    <div style={{ width: '100%', padding: '40px 0' }} className="center">
        <div className="cmenufraud-b1" />
    </div>
)

export default CMenuManageFraud;